<template>
  <div class="mainContent">
    <div class="details" id="details">
      <div class="topNav44" v-show="Show">
        <ul>
          <a @click="Click(0)" :style="{ color: num == 0 ? '#fff' : '#ccc', cursor: 'pointer' }">{{
            $t("C2.v13")
          }}</a>
          <a :style="{ color: num != 0 ? '#fff' : '#ccc',cursor: 'pointer' }" @click="Click(1)">{{
            $t("C2.v14")
          }}</a>
          <a
              v-if="$store.state.lang == 'zh-cn'"
              style="color: #ccc; margin-left: 0.55rem; cursor: pointer"
              @click="Click(9)"
          >{{ $t("C2.v14a") }}</a
          >
        </ul>
      </div>
      <content-one :content1Data="content1Data" />
      <div class="content2" id="content2">
        <div class="topNav" id="toper" ref="toper">
          <ul>
            <a
              @click="Click(0)"
              :style="{ color: num == 0 ? '#fff' : '#ccc', cursor: 'pointer' }"
              >{{ $t("C2.v13") }}</a
            >
            <a
              :style="{ color: num != 0 ? '#fff' : '#ccc', cursor: 'pointer' }"
              @click="Click(3)"
              >{{ $t("C2.v14") }}</a
            >
            <a
                v-if="$store.state.lang == 'zh-cn'"
                style="color: #ccc; margin-left: 0.55rem;cursor: pointer"
                @click="Click(9)"
            >{{ $t("C2.v14a") }}</a
            >
          </ul>
        </div>
        <div class="content2-item">
          <!-- <span>精准充气的迅猛龙</span> -->
          <div>
            <video
              class="video"
              ref="audios"
              id="audios"
              width="100%"
              height="100%"
              poster="https://image.iriding.cc/video/f2.png"
              x5-video-player-type="h5-page"
              webkit-playsinline="true"
              playsinline="true"
              disablePictureInPicture
              muted
              controls
              controlsList="nofullscreen nodownload noremote footbar noplaybackrate"
            >
              <source
                src="https://image.iriding.cc/video/f2.mp4"
                type="video/mp4"
              />
              您的浏览器不支持Video标签。
            </video>
          </div>
          <div
            class="play"
            v-show="isplay"
            v-if="browserVisible"
            @click="isPlay()"
          >
            <img src="@/assets/image/accessories/play1.png" alt="" />
          </div>
        </div>
      </div>
      <div class="content3">
        <template v-for="(item, index) in imgs">
          <div
            class="items"
            :class="index === 1 ? 'height900' : 'height1000'"
            :style="{
              backgroundImage: 'url(' + item.img + ')',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
            }"
            :key="'img-' + index"
          >
            <div class="img2 marginR color3A textaR" v-if="index === 0">
              <div class="img2-item1">
                <span class="marginT100 in-block font48 fontBold">
                  {{ $t("F2.v16") }} </span
                ><br />
                <span class="font48 fontBold"> {{ $t("F2.v17") }} </span><br />
                <span class="font24 fw400 in-block marginT30">
                  {{ $t("F2.v18a") }} </span
                ><br />
                <span class="font24 fw400"
                  >{{ $t("F2.v18b") }} {{ $t("F2.v20") }}
                </span>
              </div>
            </div>
            <div class="img3 marginL" v-if="index === 1">
              <div class="img3-item1 colorW">
                <span class="font48 in-block marginT100">{{
                  $t("F2.v21")
                }}</span>
                <ul style="margin-top: 1.65rem">
                  <li v-for="(item, index) in content3.ul" :key="'ul' + index">
                    <div>
                      <img :class="'img-' + index" :src="item.img" alt="" />
                    </div>
                    <span class="font30">{{ $t(item.text) }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="img4" v-if="index === 2">
              <div class="img4-item1 marginR color1A textaR">
                <span class="font48 marginT100 in-block fontBold">{{
                  $t("F2.v26")
                }}</span>
                <br />
                <span class="font48 in-block fontBold">{{ $t("F2.v27") }}</span
                ><br />
                <span class="font24 fw400 in-block marginT30">
                  {{ $t("F2.v28")
                  }}<sup
                    style="
                      position: absolute;
                      margin-left: -0.05rem;
                      margin-right: -0.05rem;
                      margin-top: -0.05rem;
                    "
                    class="font15"
                    >「1」</sup
                  >&nbsp;&nbsp;&nbsp;&nbsp;{{ $t("F2.v29") }}
                  {{ $t("F2.v30a") }}
                </span>
                <br />
                <span class="font24 fw400"
                  >{{ $t("F2.v30b") }}{{ $t("F2.v31") }}{{ $t("F2.v32") }}</span
                >
              </div>
            </div>
          </div>
        </template>
        <div class="item2 height900">
          <span class="font47"
            >{{ $t("F2.v34") }}{{ $t("F2.m34a") }}
            <sup style="margin-left: -0.1rem" class="font30 in-block">3</sup>
          </span>
          <br />
          <span class="font22">{{ $t("F2.v33") }}</span>
          <img
            class="floatR marginT163 marginR173"
            style="height: 6.7rem"
            src="https://image.iriding.cc/f2/img4.png"
            alt=""
          />
        </div>
        <template v-for="(item, index) in content3Img">
          <div
            class="items height1000 positionR"
            :style="{
              backgroundImage: 'url(' + item.img + ')',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
            }"
            :key="'img-' + index"
          >
            <div v-if="index === 0" class="floatR textaR marginR">
              <span class="in-block font48 marginT100">{{ $t("F2.v35") }}</span
              ><br />
              <span class="font48">{{ $t("F2.v36") }}</span
              ><br />
              <span class="font24 in-block marginT30"
                >{{ $t("F2.v37") }}{{ $t("F2.v38") }}</span
              ><br />
              <span class="font24">{{ $t("F2.v38a") }}</span>
            </div>
            <div v-if="index === 1" class="marginL">
              <span class="marginT100 in-block font24 fw400"
                >{{ $t("F2.v39") }}{{ $t("F2.v40") }}{{ $t("F2.v41") }}</span
              ><br />
              <img
                style="
                  width: 1.06rem;
                  height: 1.06rem;
                  margin-top: 0.43rem;
                  margin-bottom: 0.18rem;
                "
                src="@/assets/image/F2PC/M-1.png"
                alt=""
              /><br />
              <span class="font27 fw400">{{ $t("F2.v42") }}</span>
            </div>
            <div v-if="index === 2" class="floatR marginR textaR">
              <img
                style="width: 3.65rem; height: 0.56rem; margin-top: 6.46rem"
                src="@/assets/image/F2PC/M-2.png"
                alt=""
              /><br />
              <span class="font48 fontBold">{{ $t("F2.v43a") }}</span
              ><br />
              <span class="font24 in-block marginT30 fw400 color3A"
                >{{ $t("F2.v45") }}{{ $t("F2.v46") }}{{ $t("F2.v47") }}</span
              ><br />
              <span class="font24 fw400 color3A"
                >{{ $t("F2.v48") }}{{ $t("F2.v49") }}</span
              >
            </div>
          </div>
        </template>
      </div>
      <div class="content4">
        <div class="title">
          <span class="font60 fontBlod">{{ $t("F2.v50") }} </span>
          <br />
          <span class="font60 fontBlod">{{ $t("F2.v51") }}</span>
        </div>
        <template v-for="(item, index) in content5Img">
          <div
            class="items height900 marginL50 marginR50 marginB30"
            :style="{
              backgroundImage: 'url(' + item.img + ')',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
            }"
            :key="'img-' + index"
          >
            <div
              style="margin-right: 1.6rem"
              v-if="index === 0"
              class="floatR textaR"
            >
              <span class="font52 fontBlod in-block marginT100"
                >{{ $t("F2.v52") }}{{ $t("F2.v53") }}</span
              ><br />
              <span class="font30 fw400 color3A marginT30 in-block">{{
                $t("F2.v54")
              }}</span>
              <br />
              <img
                style="
                  width: 1.05rem;
                  height: 1.05rem;
                  margin-bottom: 0.15rem;
                  margin-top: 0.35rem;
                "
                src="@/assets/image/F2PC/M-3.png"
                alt=""
              /><br />
              <span class="font27 color59 fontBlod">{{ $t("F2.v55") }}</span>
            </div>

            <div v-if="index === 1" class="item2-inner">
              <span
                style="font-size: 0.52rem; font-weight: blod"
                class="item2-title"
                >{{ $t("F2.v56") }} {{ $t("F2.v57") }}</span
              >
              <br />
              <span style="font-size: 0.3rem" class="in-block"
                >{{ $t("F2.v58") }}
                <sup
                  class="positionA font15 color3A"
                  style="margin: 0 0 0 -0.15rem"
                  >「2」</sup
                >&nbsp;&nbsp;{{ $t("F2.v58c") }}</span
              >
              <ul class="inner-ul1 marginT30">
                <li v-for="(item, index) in content5.ul1" :key="'ul1' + index">
                  <span
                    :class="
                      index === 0
                        ? 'color4d font27 fw400'
                        : 'colorA88 font27 fw400'
                    "
                    >{{ $t(item.text) }}</span
                  ><img :src="item.img" alt="" />
                </li>
              </ul>
              <ul class="inner-ul2" style="font-size: 0.27rem">
                <li
                  v-for="(item, index) in content5.ul2"
                  :key="'ul2' + index"
                  class="flexColC fontBold"
                  :class="index !== 0 ? 'marginL60' : ''"
                >
                  <img :src="item.img" alt="" />
                  <span>{{ $t(item.text2) }} </span>
                  <span>{{ $t(item.text3) }}</span>
                </li>
              </ul>
            </div>
            <div
              style="margin-right: 1.6rem"
              v-if="index === 2"
              class="floatR textaR colorW"
            >
              <span class="font48 in-block marginT100 fontBold"
                >{{ $t("F2.v66") }}{{ $t("F2.v67") }}</span
              ><br />
              <span class="font30 in-block marginT30 fw400">{{
                $t("F2.v68a")
              }}</span
              ><br />
              <span class="font30 fw400">{{ $t("F2.v68b") }}</span>
            </div>
          </div>
        </template>
      </div>
      <div class="content5">
        <div class="item1">
          <div class="marginL">
            <span class="font48 fontBold marginT100 in-block"
              >{{ $t("F2.v9") }} </span
            ><br />
            <span class="font48 fontBold">{{ $t("F2.v69a") }}</span
            ><br />
            <span class="font24 in-block marginT30">{{ $t("F2.v70") }}</span
            ><br />
            <span class="font24">{{ $t("F2.v72") }}</span>
            <div>
              <div>
                <img src="@/assets/image/F2PC/M-4.png" alt="" />
                <span class="font27 fw400">{{ $t("F2.v73") }}</span>
              </div>
              <div style="margin-left: 0.7rem">
                <img src="@/assets/image/F2PC/M-5.png" alt="" />
                <span class="font27 fw400">{{ $t("F2.v74") }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="item2" style="background: #f9f9f9; display: flex">
          <img
            style="height: 7.27rem; margin-top: 1.41rem; margin-left: 4.71rem"
            src="https://image.iriding.cc/f2/img12.png"
            alt=""
          />
          <div class="textaR marginR" style="flex-grow: 1">
            <span class="marginT100 in-block font48 fontBold"
              >{{ $t("F2.v75") }} </span
            ><br />
            <span class="font48 fontBold">{{ $t("F2.v75a") }}</span
            ><br />
            <span
              style="position: relative"
              class="font24 fw400 in-block marginT30"
              >{{ $t("F2.v76") }}
              <sup
                style="
                  margin-left: -0.1rem;
                  margin-top: -0.05rem;
                  position: absolute;
                "
                class="font15 color3A"
                >「3」</sup
              >&nbsp;&nbsp;&nbsp;&nbsp;{{ $t("F2.v76b") }} </span
            ><br />
            <span class="font24 fw400">{{ $t("F2.v76a") }}</span
            ><br />
            <span class="az font24 fw400 in-block marginT30">
              <img class="img1" src="@/assets/image/C2PC/sun-icon.png" alt="" />
              {{ $t("C2.v91") }}</span
            ><br />
            <img class="img2" src="@/assets/image/F2PC/M-6.png" alt="" /><br />
            <span class="font30 fw400 lj">{{ $t("C2.v92") }}</span>
          </div>
        </div>
        <div class="item3">
          <div>
            <span class="font50 fontBold in-block textaC">{{
              $t("F2.v79")
            }}</span>
            <template v-for="(item, index) in content6">
              <div
                :key="'content6' + index"
                :style="{
                  backgroundImage: 'url(' + item.img + ')',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  backgroundSize: ' 100% auto',
                }"
                class="shadown"
              >
                <span
                  :class="[0, 1].includes(index) ? 'colorW' : ''"
                  class="font30 fw400 in-block marginT70"
                >
                  <img
                    v-if="index == 3"
                    style="width: 2.02rem; height: 0.32rem"
                    src="@/assets/image/F2PC/M-2.png"
                    alt=""
                  />
                  {{ $t(item.text1) }}
                  <sup
                    v-if="index === 5"
                    style="margin-top: -0.05rem; margin-left: -0.05rem"
                    class="font15 positionA"
                    >「4」</sup
                  > </span
                ><br />
                <span
                  :class="[0, 1].includes(index) ? 'colorW' : ''"
                  class="font30 fw400 in-block marginB80"
                  >{{ $t(item.text2) }}
                </span>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="content6 backColor">
        <div class="marginL font24 color3A marginR fw400">
          <div
            v-for="(item, index) in obj"
            :key="'span-' + index"
            class="referRetract"
          >
            <span
              v-if="index === 5"
              style="display: inline-block; padding-left: 0.85rem"
              >{{ $t(item) }}</span
            >
            <span v-else>{{ $t(item) }}</span>
          </div>
        </div>
      </div>
      <div class="de" id="de"></div>
      <list :content8Data="content8" />
      <footer-list :content9Data="content9Data" />
      <to-top />
    </div>
  </div>
</template>


<script>
import ToTop from "../../component/totop/toTop.vue";
import contentOne from "../../component/product/contentOne.vue";
import footerList from "../../component/product/footerList.vue";
import list from "../../component/product/list.vue";
import methods from "@/assets/js/methods";
import util from "@/util.js";

export default {
  name: "details",
  components: { ToTop, contentOne, footerList, list },
  data() {
    return {
      isplay: true,
      browserVisible: true,
      content1Data: {
        type: "F2",
        imgLeft: "https://image.iriding.cc/f2/bicyle.png",
        textTitle: "F2.v1",
        plice: "￥4999.00",
        color: "F2.v3",
        option1: "F2.v4",
        info: [
          {
            img1: require("@/assets/image/F2PC/icon-w1.png"),
            text1: "F2.v5",
            img2: require("@/assets/image/F2PC/icon-w2.png"),
            text2: "F2.v9",
          },
          {
            img1: require("@/assets/image/F2PC/icon-w3.png"),
            text1: "F2.v6",
            img2: require("@/assets/image/F2PC/icon-w4.png"),
            text2: "F2.v10",
          },
          {
            img1: require("@/assets/image/F2PC/icon-w5.png"),
            text1: "F2.v7",
            img2: require("@/assets/image/F2PC/icon-w6.png"),
            text2: "F2.v11",
          },
          {
            img1: require("@/assets/image/F2PC/icon-w7.png"),
            text1: "F2.v8",
            img2: require("@/assets/image/F2PC/icon-w8.png"),
            text2: "F2.v12",
          },
        ],
      },
      content3: {
        ul: [
          {
            img: require("@/assets/image/F2PC/black-icon1.png"),
            text: "F2.v22",
          },
          {
            img: require("@/assets/image/F2PC/black-icon2.png"),
            text: "F2.v23",
          },
          {
            img: require("@/assets/image/F2PC/black-icon3.png"),
            text: "F2.v24",
          },
          {
            img: require("@/assets/image/F2PC/black-icon4.png"),
            text: "F2.v25",
          },
        ],
      },
      content8: {
        type: "F2",
        title: "F2.v107",
        img1: "https://image.iriding.cc/f2/bicyle2.png",
        img2: "https://image.iriding.cc/f2/bicyle1.png",
        tableBottom: [
          { text1: "F2.v110", text2: "F2.v111" },
          { text1: "F2.v122", text2: "F2.v123" },
          { text1: "F2.v112", text2: "F2.v113" },
          { text1: "F2.v124", text2: "F2.v125" },
          { text1: "F2.v114", text2: "F2.v115" },
          { text1: "F2.v126", text2: "F2.v127" },
          { text1: "F2.v116", text2: "F2.v117" },
          { text1: "F2.v128", text2: "F2.v129" },
          { text1: "F2.v118", text2: "F2.v119" },
          { text1: "F2.v130", text2: "F2.v131" },
          { text1: "F2.v120", text2: "F2.v121" },
          { text1: "F2.v132", text2: "F2.v133" },
        ],
      },
      content9Data: {
        type: "F2",
        footer: [
          {
            img: require("@/assets/image/F2PC/footer-1.png"),
            text1: "F2.v135",
          },
          {
            img: require("@/assets/image/F2PC/footer-2.png"),
            text1: "F2.v136c",
          },
          {
            img: require("@/assets/image/F2PC/footer-3.png"),
            text1: "F2.v137",
          },
          {
            img: require("@/assets/image/F2PC/footer-4.png"),
            text1: "F2.v138",
          },
          {
            img: require("@/assets/image/F2PC/footer-5.png"),
            text1: "F2.v139",
          },
          {
            img: require("@/assets/image/F2PC/footer-6.png"),
            text1: "F2.v140",
          },
        ],
      },
      content5: {
        icon: [
          {
            img: require("@/assets/image/C2PC/black-icon1.png"),
            text1: "C2.v31",
            text2: "C2.v32",
          },
          {
            img: require("@/assets/image/C2PC/black-icon2.png"),
            text1: "C2.v33",
            text2: "C2.v34",
          },
        ],
        ul1: [
          {
            text: "C2.v41",
            img: require("@/assets/image/C2PC/electric-icon1.png"),
          },
          {
            text: "C2.v42",
            img: require("@/assets/image/C2PC/electric-icon2.png"),
          },
        ],
        ul2: [
          {
            text1: "C2.v41",
            img: require("@/assets/image/F2PC/el-out25.png"),
            text2: "C2.v44",
            text3: "C2.v45",
          },
          {
            text1: "C2.v41",
            img: require("@/assets/image/F2PC/el-out50.png"),
            text2: "C2.v46",
            text3: "C2.v47",
          },
          {
            text1: "C2.v41",
            img: require("@/assets/image/F2PC/el-out75.png"),
            text2: "C2.v48",
            text3: "C2.v49",
          },
        ],
      },
      content6: [
        {
          img: require("@/assets/image/F2PC/imgS1.png"),
          text1: "F2.v80",
          text2: "F2.v81",
        },
        {
          img: require("@/assets/image/F2PC/imgS2.png"),
          text1: "F2.v82",
          text2: "F2.v83",
        },
        {
          img: require("@/assets/image/F2PC/imgS3.png"),
          text1: "F2.v84",
          text2: "F2.v85",
        },
        {
          img: require("@/assets/image/F2PC/imgS4.png"),
          text1: "F2.v86",
          text2: "F2.v87",
        },
        {
          img: require("@/assets/image/F2PC/imgS5.png"),
          text1: "F2.v88",
          text2: "F2.v89",
        },
        {
          img: require("@/assets/image/F2PC/imgS6.png"),
          text1: "F2.v90",
          text2: "F2.v91",
        },
        {
          img: require("@/assets/image/F2PC/imgS7.png"),
          text1: "F2.v92",
          text2: "F2.v93",
        },
      ],
      info: [
        {
          img1: require("@/assets/image/C2PC/icon-top1.png"),
          text1: "C2.v5",
          img2: require("@/assets/image/C2PC/icon-top2.png"),
          text2: "C2.v9",
        },
        {
          img1: require("@/assets/image/C2PC/icon-top3.png"),
          text1: "C2.v6",
          img2: require("@/assets/image/C2PC/icon-top4.png"),
          text2: "C2.v10",
        },
        {
          img1: require("@/assets/image/C2PC/icon-top5.png"),
          text1: "C2.v7",
          img2: require("@/assets/image/C2PC/icon-top6.png"),
          text2: "C2.v11",
        },
        {
          img1: require("@/assets/image/C2PC/icon-top7.png"),
          text1: "C2.v8",
          img2: require("@/assets/image/C2PC/icon-top8.png"),
          text2: "C2.v12",
        },
      ],
      imgs: [
        {
          img: "https://image.iriding.cc/f2/img1.png",
          id: 2,
          text1: "details.v38",
          text2: "details.v362",
          text3: "details.v364",
          text4: "details.v39",
          text5: "details.v391",
        },
        {
          img: "https://image.iriding.cc/f2/img2.png",
          id: 3,
          text1: "details.v40",
          text2: "details.v362",
          text3: "details.v411",
          text4: "details.v39",
          text5: "details.v413",
        },
        {
          img: "https://image.iriding.cc/f2/img3.png",
          id: 4,
          text1: "details.v42",
          text2: "details.v362",
          text3: "details.v421",
          text4: "details.v39",
          text5: "details.v43",
        },
      ],
      content3Img: [
        {
          img: "https://image.iriding.cc/f2/img5.png",
          id: 3,
          text1: "details.v40",
          text2: "details.v362",
          text3: "details.v411",
          text4: "details.v39",
          text5: "details.v413",
        },
        {
          img: "https://image.iriding.cc/f2/img6.png",
          id: 3,
          text1: "details.v40",
          text2: "details.v362",
          text3: "details.v411",
          text4: "details.v39",
          text5: "details.v413",
        },
        {
          img: "https://image.iriding.cc/f2/img7.png",
          id: 3,
          text1: "details.v40",
          text2: "details.v362",
          text3: "details.v411",
          text4: "details.v39",
          text5: "details.v413",
        },
      ],

      content5Img: [
        {
          img: "https://image.iriding.cc/f2/img8.png",
          id: 3,
          text1: "details.v40",
          text2: "details.v362",
          text3: "details.v411",
          text4: "details.v39",
          text5: "details.v413",
        },
        {
          img: "https://image.iriding.cc/f2/img9.png",
          id: 3,
          text1: "details.v40",
          text2: "details.v362",
          text3: "details.v411",
          text4: "details.v39",
          text5: "details.v413",
        },
        {
          img: "https://image.iriding.cc/f2/img10.png",
          id: 3,
          text1: "details.v40",
          text2: "details.v362",
          text3: "details.v411",
          text4: "details.v39",
          text5: "details.v413",
        },
      ],
      mes: [
        {
          text1: "details.v53",
          text2: "details.v54",
          text3: "details.v55",

          text4: "details.v56",
          text5: "details.v57",
          text6: "details.v58",
        },
        {
          text1: "details.v59",
          text2: "details.v60",
          text3: "details.v61",

          text4: "details.v62",
          text5: "details.v63",
          text6: "details.v631",
        },
        {
          text1: "details.v64",
          text2: "details.v65",
          text3: "details.v66",
        },
      ],
      c5: [
        {
          text1: "details.v194",
          text2: "details.v195",
        },
        {
          text1: "details.v196",
          text2: "details.v197",
        },
        {
          text1: "details.v198",
          text2: "details.v199a",
          text3: "details.v199b",
        },
        {
          text1: "details.v200",
          text2: "details.v201",
        },
        {
          text1: "details.v202",
          text2: "details.v203",
        },
        {
          text1: "details.v204",
          text2: "details.v205",
        },
        // {
        //   text1: "details.v206",
        //   text2: "±1psi ",
        // },
        {
          text1: "details.v208",
          text2: "details.v209",
        },
        /*{
          text1: "details.v210",
          text2: "5V---3A",
        },*/
      ],

      obj: ["F2.v96", "F2.v97", "F2.v98", "F2.v99", "F2.v100", "F2.v106a"],
      activeIndex: false,
      Show: false,
      scrollTop: "",
      toper: "",
      indexNumTop: "",
      num: 0,
      color: false,
    };
  },
  mounted() {
    console.log('lang:', this.$store.state.lang)
    this.$store.state.isShowFooter = true;
    window.addEventListener("scroll", this.getScroll);
    this.$refs.audios.addEventListener("play", this.handlePlay);
    this.$refs.audios.addEventListener("pause", this.handlePause);
    if (util.isFirefox() || util.isSafari()) {
      this.browserVisible = false;
    } else {
      this.browserVisible = true;
    }
    let timer = null;
    setTimeout(() => {
      methods.sizeadapt(1920);
    }, 500);
    window.onresize = () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      timer = setTimeout(() => {
        methods.sizeadapt(1920);
      }, 500);
    };
  },
  methods: {
    handlePlay() {
      this.$refs.audios.play();
      this.isplay = false;
    },
    handlePause() {
      this.$refs.audios.pause();
      this.isplay = true;
    },
    isPlay() {
      let myVideo = document.querySelector("#audios");
      if (myVideo.paused) {
        this.isplay = false;
        myVideo.play();
      } else {
        this.isplay = true;
        myVideo.paused();
      }
    },
    getScroll() {
      this.scrolldata = {
        showMenu: this.Show,
        topid: "#content2",
        target: "#de",
      };
      let data = methods.getScroll(this.scrolldata);
      this.Show = data.showMenu;
      this.$store.state.isShowHeader = data.isShowHeader;
    },
    Click(num) {
      this.num = num;
      if (num == 9) {
        const routeUrl = this.$router.resolve({
          path: "/pdf",
          query: { url: `https://image.iriding.cc/manual/EF2.pdf` },
        });

        window.open(routeUrl.href, "_blank");
        return;
      }
      if (num === 0) {
        document.getElementById("content2").scrollIntoView();
        this.num = 0;
      } else if (num === 3) {
        document.querySelector("#de").style.height = "80px";
        this.color = true;
        document.getElementById("de").scrollIntoView();
      } else if (num === 1) {
        document.getElementById("de").scrollIntoView();
        this.color = true;
      }
    },
  },
};
</script>

<style scoped lang="less">
#de {
  padding-top: 50px;
  margin-top: -55px;
}
@import "@/assets/less/screenF2.less";
@import "@/assets/less/common.less";
</style>
