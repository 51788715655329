<template>
  <div>
    <multi v-if="ismulti" />
    <pedelecs v-else />
  </div>
</template>

<script>
import Pedelecs from "../../component/product/f2.vue";
import Multi from "../../component/product/multiF2.vue";
export default {
  name: "pedelecs_f2",
  components: {
    Multi,
    Pedelecs,
  },
  mounted() {
    if (!["zh-cn", "zh-hk"].includes(localStorage.getItem("lans"))) {
      this.ismulti = true;
    }
  },
  data() {
    return {
      ismulti: false,
    };
  },
};
</script>

<style>
</style>